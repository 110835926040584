//import { fade } from '@material-ui/core/styles/colorManipulator';
//import {grey} from '@material-ui/core/colors';
//
const styles = (theme) => ({
    root:{
        height:'100vh',
        position:'relative',
        overflow:'hidden'
    },
    videoContainer:{    
        position:'absolute',
        top:0,
        bottom:0,
        width:'100%',
        height:'100vh',
        overflow:'hidden',
        "&:after": {
            position: "absolute",
            zIndex: "3",
            width: "100%",
            height: "100%",
            content: '""',
            display: "block",
            background: 'rgba(0,0,0,0.6)',
        }
    },
    video:{
        position:'absolute',
        width:'100%',
        //minHeight:'100vh',
        zIndex:-1,
    },
    textContainer:{
        width:'100%',
        position:'relative',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color:'white',
        zIndex:10,
        height:'100vh'
    },
    textTitleA:{
        fontFamily:'Audiowide',
        letterSpacing:theme.spacing.unit*4,
        color:'white',
        fontWeight:'300',
        fontSize:'5.5em'
    },
    textTitleB:{
        letterSpacing:theme.spacing.unit*4,
        color:'white',
        fontWeight:'600',
        fontSize:'7em'
    },
    textSubtitle:{
        letterSpacing:theme.spacing.unit*2,
        color:'white',
        fontWeight:'600'
    },
    textContent:{
        letterSpacing:theme.spacing.unit*0.2,
        color:'white',
        fontWeight:'400'
    },
    
})

export default styles;