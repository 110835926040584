import React, { Component } from 'react';
import './App.css';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
//import PublicRouters from './routes/router.jsx';
import Principal from './layouts/Principal/Principal.jsx';
import createBrowserHistory from 'history/createBrowserHistory';
const history =  createBrowserHistory();


const theme = createMuiTheme({
  palette: {
    primary: { main: '#003366' }, // Purple and green play nicely together. #091635 '#4ac4f3 #00359B'
    secondary: {main: '#2196F3'} //2196F3
  },
  typography: {
    useNextVariants: true,
    fontFamily:[
      'Titillium Web',
      'Nunito Sans',
      'Open Sans',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },
});



class App extends Component {




  render() {

    return (
      <MuiThemeProvider theme={theme}>
        <div sytle={{flexGrow:1, overflowX:'hidden'}}>
          <Principal history={history}/>
        </div>
      </MuiThemeProvider>
    );
  }
}

export default App;
