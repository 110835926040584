import React, {Component} from 'react';
import {
  Grid,
  Typography,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import styles from './styles.jsx';
import cx from 'classnames';
import moment from 'moment-timezone'

class Footer extends Component{


  render(){

    const {classes, content, onChange} = this.props;

    return(
      <div className={classes.root}>
        <div className={classes.subcontainer}>
          <div className={classes.container}>
            <Grid container alignItems='center' spacing={2} wrap='nowrap'>
              <Grid item>
                <Grid container>
                  <Grid item>
                    <Typography className={classes.title}> 
                      TRIONIX
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs>
                <Grid container spacing={8}>
                  {
                    content.content.links.map((item,key) => {
                      
                      return(
                        <Grid item key={key.toString()}>
                          <div onClick={() => onChange(item.link)} className={classes.navlink}>{item.name}</div>
                        </Grid>
                      )
                    })
                  }
                  <Grid item>
                    <div onClick={() => onChange(content.content.quotation.link)} className={classes.navlinkB}>{content.content.quotation.name}</div>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <Grid container spacing={2}>
                  {
                    content.content.socialmedia.map((item,key)=>{
                      return(
                        <Grid item key={key.toString()}>
                          <div className={cx({
                            [classes.imageContainer]:true,
                            [classes.facebook]: item.name === 'facebook',
                            [classes.twitter]: item.name === 'twitter',
                            [classes.instagram]: item.name === 'instagram',
                            [classes.linkedin]: item.name === 'linkedin'
                          })}>
                            <a href={item.link} target="_blank">
                              <img src={item.icon} alt='' className={classes.image}/>
                            </a>
                          </div>
                        </Grid>
                      )
                    })
                  }
                </Grid>
              </Grid>
          </Grid>
          </div>
          <div className={classes.legalContainer}>
            <Typography className={classes.legal}>
            {`COPYRIGHT © ${moment().format('YYYY')} TRIONIX TECHNOLOGIES`}
            </Typography>
          </div>
        </div>
      </div>
    )
  }
}

export default withStyles(styles)(Footer);
