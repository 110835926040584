import facebook from '../../assets/Home/Social/facebook.png';
import twitter from '../../assets/Home/Social/twitter.png';
import linkedin from '../../assets/Home/Social/linkedin.png';
import instagram from '../../assets/Home/Social/instagram.png';

export const contentData = {
  spanish:{
    content:{
      title:'Trionix',
      links:[
        {name:'Home', link:'/home'},
        {name:'Servicios', link:'/services'},
        {name:'Tecnologías', link:'/technologies'},
        {name:'Nosotros', link:'/about'},
      ],
      quotation:{
        name:'Solicita tu cotización', link:'/contact'
      },
      socialmedia:[
        {icon:twitter,name:'twitter', link:'https://x.com/trionixtech'},
        {icon:facebook,name:'facebook', link:'https://www.facebook.com/TrionixTechnologies'},
        {icon:instagram,name:'instagram', link:'https://www.instagram.com/trionix.io/'},
        {icon:linkedin,name:'linkedin', link:'https://www.linkedin.com/company/trionix-technologies'},
      ]
    },
    contentB:{
      title:'Secciones',
      content:['Home','Soluciones','Proyectos','Nosotros','Contacto']
    },
    contentC:{
      title:'Contáctanos',
      content:[
        
      ]
    }
  },
  english:{
    content:{
      title:'Trionix',
      links:[
        {name:'Home', link:'/home'},
        {name:'Services', link:'/services'},
        {name:'Technologies', link:'/technologies'},
        {name:'About us', link:'/about'},
      ],
      quotation:{
        name:'Request your quote', link:'/contact'
      },
      socialmedia:[
        {icon:twitter,name:'twitter', link:'https://x.com/trionixtech'},
        {icon:facebook,name:'facebook', link:'https://www.facebook.com/TrionixTechnologies'},
        {icon:instagram,name:'instagram', link:'https://www.instagram.com/trionix.io/'},
        {icon:linkedin,name:'linkedin', link:'https://www.linkedin.com/company/trionix-technologies'},
      ]
    },
    contentB:{
      title:'Secciones',
      content:['Home','Soluciones','Proyectos','Nosotros','Contacto']
    },
    contentC:{
      title:'Contáctanos',
      content:[
        
      ]
    }
  }
}