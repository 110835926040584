import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import styles from './styles.jsx';
import {
  Fade,
  Dialog,
  Grid
} from '@material-ui/core';
import LoaderIcon from 'react-loader-spinner'

import logo from '../../assets/logo.png';


function Transition(props) {
  return <Fade {...props} />;
}

class Loader extends Component{

  onClose = () => {
    console.log('Cerrar')
  }

  render(){

    const {open, classes} = this.props;
    
    return(
      <Dialog
          fullScreen  
          open={open}
          onClose={this.onClose}
          TransitionComponent={Transition}
        >
          <div className={classes.container}>
            <Grid container direction='column' alignItems='center' justify='center' spacing={3}>
              <Grid item>
                <img src={logo} alt={''} className={classes.logo}/>
              </Grid>
              <Grid item>
                <div className={classes.spinnerContainer}>
                  <LoaderIcon 
                    type="Bars"
                    color="#4b6bf5"
                  />  
                </div>
              </Grid>
            </Grid>
          </div>
          
        </Dialog>
    )
  }
}

export default withStyles(styles)(Loader);
