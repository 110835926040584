import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import styles from './styles.jsx';
import{Typography, Grid, Fade } from '@material-ui/core';

class SectionA extends Component{

    render(){

        const {classes,content} = this.props;

        return(
            <div className={classes.root}>
                <div className={classes.videoContainer}/>
                <div className={classes.textContainer}>
                    <div style={{width:'100%'}}>
                        <Grid container direction='column' alignItems='center' spacing={1}>
                            <Grid item>
                                <Fade in timeout={3000}>
                                    <Grid container>
                                        <Grid item>
                                            <Typography variant='h1' className={classes.textTitleA}>
                                                TRIONIX
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Fade>    
                            </Grid>
                            <Grid item>
                                <Fade in timeout={6000}>
                                    <Typography variant='h4' className={classes.textSubtitle}>
                                    {content.video.subname}
                                    </Typography>
                                </Fade>  
                            </Grid>
                            <Grid item style={{height:'32px'}}/>
                            <Grid item>
                                <Fade in timeout={9000}>
                                    <Typography variant='h6' className={classes.textContent}>
                                    {content.video.phrase}
                                    </Typography>
                                </Fade>            
                            </Grid>
                        </Grid>
                    </div>            
                </div>
            </div>
        )
    }
}

export default withStyles(styles)(SectionA);