//import { fade } from '@material-ui/core/styles/colorManipulator';
import {grey} from '@material-ui/core/colors';

const styles = (theme) => ({
    section:{
        padding:'100px 0px',
        background:grey[50]
    },
    container:{
        boxSizing:'border-box',
        width:'1200px',
        margin:'auto',
        padding:'16px',
        [theme.breakpoints.up('lg')]: {
            width:'1200px',
        },
        [theme.breakpoints.only('md')]: {
            width:'950px',
        },
    },
    divider:{
        marginTop:'32px',
        width:'50px',
        minHeight:'5px',
        background:grey[300]
    },
    title:{
        fontWeight:'700',
        maxWidth:'500px',
    },
    content_container:{
        maxWidth:'800px',
        margin:'40px',
        color:grey[500],
    },
    content:{
        color:grey[700],
        fontWeight:'400',
        fontSize:'1.1em'
    },
    subcontainer:{
        position:'relative',
        padding:'0px 0px',
    },
    circle:{
        position:'relative',
        zIndex:-1,
        borderRadius:'50%',
        width:'700px',
        height:'700px',
        border:`6px solid ${grey[300]}`
    },
    itemContainerA:{
        position:'absolute',
        margin:0,
        left:'65%',
        top:'21%',
        [theme.breakpoints.only('md')]: {
            left:'69%',
            top:'21%',
        },
        
        
    },
    itemContainerB:{
        position:'absolute',
        margin:0,
        left:'13.5%',
        top:'21%',
        [theme.breakpoints.only('md')]: {
            left:'4%',
            top:'21%',
        },
    },
    itemContainerC:{
        position:'absolute',
        margin:0,
        left:'37.8%',
        bottom:'-5.5%',
        [theme.breakpoints.only('md')]: {
            left:'34.1%',
            bottom:'-5.5%',
        },
    },
    
    image:{
        width:'600px',
        margin:'auto',
        [theme.breakpoints.only('md')]: {
            width:'450px',
        },
    },
    android:{
        position:'absolute',
        top:'5%',
        left:'13%'
    },
    ios:{
        position:'absolute',
        top:'38%',
        left:'12%'
    },
    ionic:{
        position:'absolute',
        top:'72%',
        left:'14%'
    },
    react:{
        position:'absolute',
        top:'5%',
        left:'76%'
    },
    xamarin:{
        position:'absolute',
        top:'38%',
        left:'78%'
    },
    flutter:{
        position:'absolute',
        top:'72%',
        left:'76%'
    },
    
    
    
})

export default styles;