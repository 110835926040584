//import { fade } from '@material-ui/core/styles/colorManipulator';
import {grey} from '@material-ui/core/colors';

const styles = (theme) => ({
  appbar:{
    background: `transparent`,
    boxShadow:'none',
    padding: `${theme.spacing.unit*4}px 100px`,
    transition: theme.transitions.create(['padding','box-shadow','background'], {
      easing: theme.transitions.easing.easeIn,
      duration: 500,
    }),
    [theme.breakpoints.down('md')]: {
      padding: `${theme.spacing.unit*1}px ${theme.spacing.unit*0}px`,
    },
  },
  appbar2:{
    background: grey[100],
    padding: `${theme.spacing.unit*0}px 100px`,
    transition: theme.transitions.create(['padding','box-shadow','background'], {
      easing: theme.transitions.easing.easeIn,
      duration: 500,
    }),
    [theme.breakpoints.down('md')]: {
      padding: `${theme.spacing.unit*1}px ${theme.spacing.unit*0}px`,
    },
  },
  toolbar:{
    width:'100%',
    color:'white',
    fontSize:'20px',
    fontWeight:'600',
    background:'transparent',
  },
  toolbarSpanish:{
    margin:'auto',
    marginLeft:'10px',
  },
  toolbarEnglish:{
    margin:'auto',
    marginLeft:'0px',
  },

  sections:{
  },
  imgContainer:{
    width:'54px',
    opacity:0.9,
    transition: theme.transitions.create(['width','height'], {
      easing: theme.transitions.easing.easeIn,
      duration: 500,
    }),
    [theme.breakpoints.only('md')]: {
      width:'54px',
  },
  },
  imgContainer2:{
    width:'50px',
    opacity:0.85,
    padding:'8px 0px',
    transition: theme.transitions.create(['width','height'], {
      easing: theme.transitions.easing.easeIn,
      duration: 500,
    }),
    [theme.breakpoints.down('md')]: {
      width:'50px',
    },

  },
  menuButton:{
    color:'white',
  },
  menuIcon:{
    fontSize:'150%'
  }

})

export default styles;
