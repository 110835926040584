import React, {Component} from 'react';
import {Drawer,  Grid, AppBar, Toolbar, Icon, IconButton, Divider } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import LanguageButton from '../../UI/Buttons/LanguageButton'
import logo from '../../../assets/logoWhite.png';
import styles from './styles.jsx';
import cx from 'classnames';

class HeaderMobile extends Component{

  state = {
    open: false,
  }

  shouldComponentUpdate(nextProps, nextState){
    return true
  }

  onChangeTab = (event, id) => {
    this.setState({selectedTab:id})
  }

  onOpenMenu = () => {
    this.setState({open:true})
  }

  onCloseMenu = () => {
    this.setState({open:false})
  }

  onPrevChangeItem = (item) => {
    this.setState({open:false})
    this.props.onChange(item)
  }


  render(){

    const {classes, sections, mode, urls, language, onChangeLanguage} = this.props;
    const {open} = this.state;

    return(
      <div >
        <Drawer 
          open={open} 
          onClose={this.onCloseMenu} 
          anchor='right'
          classes={{
            paper:classes.drawer
          }}
        >
          <div className={classes.drawerContainer}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Grid container direction='column' alignItems={'center'}>
                  <img src={logo} alt={''} className={classes.logo}/>
                </Grid>
                
              </Grid>
              <Grid item xs={12}>
                <Divider style={{background:'rgba(255,255,255,0.5)', height:'3px'}}/>
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  {sections.map((item,key) => {
                    return(
                      <Grid item key={key.toString()} xs={12}>
                        <div className={classes.item} onClick={() => this.onPrevChangeItem(urls[key])}>
                          {item}
                        </div> 
                      </Grid>
                    )
                  })}
                </Grid>
              </Grid>
            </Grid>
          </div>
          
        </Drawer>
        <AppBar position='fixed' className={classes.appbar}>
          <Toolbar className={classes.toolbar}>
            <Grid container alignItems='center' spacing={2}>
              <Grid item>
                <LanguageButton language={language} onChange={onChangeLanguage}/>
              </Grid>
              <Grid item xs/>
              <Grid item>
                <Grid container spacing={1}>
                  <IconButton className={cx({[classes.menuButton]:true, [classes.menuButtonMode]:mode})} onClick={this.onOpenMenu}>
                    <Icon className={classes.menuIcon}>
                      menu
                    </Icon>
                  </IconButton>
                </Grid>
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
      </div>
    )
  }
}

export default withStyles(styles)(HeaderMobile);
