export const contentData = {
  spanish:{
    sectionA:{
      name:'Contacto',
      //subnameA:'xand',
      //subnameB:'box',
      phrase:'Permítenos brindarte la mejor atención'
    },
    sectionB:{
      title:'¡Pongámonos en contacto!',
      content:'Contacto',
      form:{
        name:'Nombre',
        email:'Correo',
        subject:'Asunto',
        phone:'Teléfono',
        message:'Mensaje',
        button:'Enviar Mensaje'
      },
      contact:{
        address:{
          title:'Dirección',
          content:['Av Coyocán 1622','Del Valle Centro,','Benito Juárez, CDMX,', 'México, 03100.']
        },
        phone:{
          title:'Teléfono',
          content:['+52 56 3689 2214']
        },
        email:{
          title:'Correo electrónico',
          content:['contacto@trionix.io']
        }
      },
      snackbarSuccess:{
        message:'¡Correo enviado!',
        duration:3000,
        variant:'success'
      },
      snackbarError:{
        message:'¡Hubo un problema!',
        duration:3000,
        variant:'error'
      },
      data:[
        {icon:'developer_board', title:'Customer Support', content:'Lorem ipsum dolor sit amet, consectetur adipiscing metus. elit. Quisque rutrum pellentesque imperdiet.'},
        {icon:'developer_board', title:'Sliders', content:'Lorem ipsum dolor sit amet, consectetur adipiscing metus. elit. Quisque rutrum pellentesque imperdiet.'},
        {icon:'developer_board', title:'Buttons', content:'Lorem ipsum dolor sit amet, consectetur adipiscing metus. elit. Quisque rutrum pellentesque imperdiet.'},
        {icon:'developer_board', title:'HTML5 / CSS3 / JS', content:'Lorem ipsum dolor sit amet, consectetur adipiscing metus. elit. Quisque rutrum pellentesque imperdiet.'},
        {icon:'developer_board', title:'Icons', content:'Lorem ipsum dolor sit amet, consectetur adipiscing metus. elit. Quisque rutrum pellentesque imperdiet.'},
        {icon:'developer_board', title:'Lightbox', content:'Lorem ipsum dolor sit amet, consectetur adipiscing metus. elit. Quisque rutrum pellentesque imperdiet.'},
        {icon:'developer_board', title:'Customer Support', content:'Lorem ipsum dolor sit amet, consectetur adipiscing metus. elit. Quisque rutrum pellentesque imperdiet.'},
        {icon:'developer_board', title:'Sliders', content:'Lorem ipsum dolor sit amet, consectetur adipiscing metus. elit. Quisque rutrum pellentesque imperdiet.'},
        {icon:'developer_board', title:'Buttons', content:'Lorem ipsum dolor sit amet, consectetur adipiscing metus. elit. Quisque rutrum pellentesque imperdiet.'}
      ]
    },
  },
  english:{
    sectionA:{
      name:'Contact',
      //subnameA:'xand',
      //subnameB:'box',
      phrase:'Let us give you the best care'
    },
    sectionB:{
      title:"Let's get in touch!",
      content:'Contact',
      form:{
        name:'First name',
        email:'Mail',
        subject:'Subject',
        phone:'Phone',
        message:'Message',
        button:'Send Message'
      },
      contact:{
        address:{
          title:'ADDRESS',
          content:['Patricio Sanz 1513 Int. 7B,','Tlacoquemécatl del Valle,','Benito Juárez, CDMX,', 'México, 03200.']
        },
        phone:{
          title:'PHONE',
          content:['+52 1 55 5050 1023']
        },
        email:{
          title:'E-MAIL',
          content:['contacto@trionix.io']
        }
      },
      snackbarSuccess:{
        message:'¡Correo enviado!',
        duration:3000,
        variant:'success'
      },
      snackbarError:{
        message:'¡Hubo un problema!',
        duration:3000,
        variant:'error'
      },
      data:[
        {icon:'developer_board', title:'Customer Support', content:'Lorem ipsum dolor sit amet, consectetur adipiscing metus. elit. Quisque rutrum pellentesque imperdiet.'},
        {icon:'developer_board', title:'Sliders', content:'Lorem ipsum dolor sit amet, consectetur adipiscing metus. elit. Quisque rutrum pellentesque imperdiet.'},
        {icon:'developer_board', title:'Buttons', content:'Lorem ipsum dolor sit amet, consectetur adipiscing metus. elit. Quisque rutrum pellentesque imperdiet.'},
        {icon:'developer_board', title:'HTML5 / CSS3 / JS', content:'Lorem ipsum dolor sit amet, consectetur adipiscing metus. elit. Quisque rutrum pellentesque imperdiet.'},
        {icon:'developer_board', title:'Icons', content:'Lorem ipsum dolor sit amet, consectetur adipiscing metus. elit. Quisque rutrum pellentesque imperdiet.'},
        {icon:'developer_board', title:'Lightbox', content:'Lorem ipsum dolor sit amet, consectetur adipiscing metus. elit. Quisque rutrum pellentesque imperdiet.'},
        {icon:'developer_board', title:'Customer Support', content:'Lorem ipsum dolor sit amet, consectetur adipiscing metus. elit. Quisque rutrum pellentesque imperdiet.'},
        {icon:'developer_board', title:'Sliders', content:'Lorem ipsum dolor sit amet, consectetur adipiscing metus. elit. Quisque rutrum pellentesque imperdiet.'},
        {icon:'developer_board', title:'Buttons', content:'Lorem ipsum dolor sit amet, consectetur adipiscing metus. elit. Quisque rutrum pellentesque imperdiet.'}
      ]
    },
  }
}