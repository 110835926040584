//import { fade } from '@material-ui/core/styles/colorManipulator';
import {grey} from '@material-ui/core/colors';


const styles = (theme) => ({
  appbar:{
    background: 'transparent',
    boxShadow:'none',
    padding: `${theme.spacing.unit*1}px 100px`,
    transition: theme.transitions.create(['padding','box-shadow','background'], {
      easing: theme.transitions.easing.easeIn,
      duration: 500,
    }),
    [theme.breakpoints.down('md')]: {
      padding: `${theme.spacing.unit*1}px ${theme.spacing.unit*1}px`,
    },
  },
  toolbar:{
    color:'white',
    fontSize:'20px',
    fontWeight:'600',
    background:'transparent',

  },
  imgContainer:{
    width:'36px',
    opacity:0.9,
    transition: theme.transitions.create(['width','height'], {
      easing: theme.transitions.easing.easeIn,
      duration: 500,
    }),
  },
  menuButton:{
    color:'white',
  },
  menuButtonMode:{
    background:'white',
    color:grey[900],
    borderRadius:'8px',
    boxShadow:'0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)'
  },
  menuIcon:{
    fontSize:'150%'
  },
  title:{
    color:'white',
    fontWeight:'600'
  },
  drawer:{
    background: theme.palette.secondary.main,
    color:'white',
    maxWidth:'190px'
  },
  drawerContainer:{
    margin: theme.spacing.unit*2
  },
  logo:{
    width:'54px',
    margin:'auto',
  },
  item:{
    background:'rgba(255,255,255,0.1)',
    borderRadius:'16px',
    padding:'8px',
    boxShadow: '0 3px 6px rgba(255,255,255,0.05), 0 3px 6px rgba(255,255,255,0.1)'
  }

})

export default styles;
